import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpSecureClient } from '../../../shared/services/http/httpSecureClient';
import { AuthGuardService } from '../../../shared/services/authentication/auth-guard.service';
import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { HttpParams } from '@angular/common/http';


@Injectable()
export class EstatOperacionsService  {
    [x: string]: any;
    displayedItems = [];

    constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
        this.displayedItems['checkedValues'] = 0;
    }

 
    getEstatOperacioDetails(exp: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            const params = new HttpParams().set('exp', exp);
    
            const url = config.urls['apiUrl'] + api.endpoints['urlestatoperacioquery'] + "/{exp}";
            return this.http.get(url, params);
        }
    }
    
    
    
}