  <div class="bg-welcome" style="position: fixed; left:0; top: 0; width: 100%; height: 100%;">
    <div class="bg-welcome__inner">
      <div class="animated bounce">
        <div class="panel-side panel-login">
          <div class="panel-side__sidebar">
            <div>
              <h1 i18n>Si et plau, inicia sessió</h1>

              <p i18n>Per poder veure tota la informació de SomDen Web, primer cal iniciar sessió</p>
            </div>
          </div>

          <div class="panel-side__content">
            <div>
              <form name="form" (ngSubmit)="f.form.valid && doLogin()" #f="ngForm" novalidate>
                <div class="logo">
                  <img src="assets/images/logo.png">
                </div>

                <div class="form-group">
                  <div class="text-center">{{ this.institucion.NombreInstitucion }}</div>
                </div>                  

                <mat-horizontal-stepper #stepper>
                  <mat-step >               
                    <form >
                      <div class="form-group">
                        <!-- TODO: Reactivate it according to the original behaviour: only allow emails, not single users -->
                        <!--<input type="email" class="form-control" name="username" [(ngModel)]="login.username" #username="ngModel" [ngClass]="{ 'is-invalid': f.submitted && username.invalid }" required email>-->
                        <input type="email" placeholder="Usuari" i18n-placeholder class="form-control" id="login_username" name="username" [(ngModel)]="login.username" #username="ngModel" [ngClass]="{ 'is-invalid': f.submitted && username.invalid }" required>
      
                        <div *ngIf="f.submitted && username.invalid" class="invalid-feedback">
                          <div *ngIf="username?.errors.required" i18n>El camp usuari és obligatori</div>
                          <!--<div *ngIf="username.errors.email">No has inserit un email correcte</div>-->
                        </div>
                      </div>
                      <div class="form-group">
                        <input type="password" placeholder="Password" i18n-placeholder class="form-control" id="login_password" name="password" [(ngModel)]="login.password" #password="ngModel" [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" required>
      
                        <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                          <div *ngIf="password?.errors.required" i18n>El camp contrasenya és obligatori</div>
                        </div>
                      </div>
              
                    </form>               
                  </mat-step>
                  <mat-step >
                    <form >
                      <p i18n>Entreu la contrasenya d'un sol ús (OTP)</p>
                      <div class="form-group">
                        <!-- <app-form-text-edit class="form-control" [(value)]="login.otpPassword"></app-form-text-edit> -->
                        <input type="text" placeholder="OTP" i18n-placeholder class="form-control" id="otpPassword" name="otpPassword" [(ngModel)]="login.otpPassword" #otpPassword="ngModel" required>
                      </div>
                    </form>
                  </mat-step>                  
                </mat-horizontal-stepper>

                <button id="login_button"  class="btn btn-primary btn-block" [disabled]="!this.validInstitituion()" i18n>Iniciar Sessió</button>
                <div class="alert alert-danger" [hidden]="this.validInstitituion()"><p i18n> Necessita un ID d'institució vàlid per iniciar sessió</p>
                    <button type="button" class="btn btn-outline-info btn-sm" data-container="body" data-toggle="popover" data-placement="bottom" data-content="Cal especificar un ID d'institució vàlid en la URL darrere la paraula login. Per exemple: http://somdenweb.com/login/12345" i18n-data-content>
                      <i class="fa fa-info-circle"></i>
                    </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div *ngIf="this.logoPersonalitzat !==null" style="display: flex; justify-content: center; align-items: center; margin: 20px;">
          <img [src]="this.logoPersonalitzat">
        </div>
      </div>
    </div>
  </div>
