<app-dialog-header label="Denúncia" i18n-label *ngIf="this.dialogData !== undefined"></app-dialog-header>
<app-toolbar>
    <!--<app-tool-button text="Actualitzar"  i18n-text (click)="updateDenuncies();" color="has-color-blue" icon="fa-sync"></app-tool-button>-->
    <app-tool-button text="Editar" i18n-text (click)="onMakeGridEditable();" color="has-color-orange" icon="fa-edit"
        [disabled]="this.denunciaDetail.tancat || this.denunciaDetail.traspasado || !this.isReadOnly || !this.auth.UserCanWrite(this.auth.Pages.Denuncies)">
    </app-tool-button>
    <app-tool-button text="Guardar" i18n-text (click)="saveDenuncia();" color="has-color-green" icon="fa-save"
        [disabled]="this.isReadOnly" *ngIf="this.auth.UserCanWrite(this.auth.Pages.Denuncies)"></app-tool-button>
    <app-tool-button text="Cancel·lar" i18n-text (click)="cancelDenuncia();" color="has-color-red" icon="fa-times"
        [disabled]="this.isReadOnly || !this.auth.UserCanWrite(this.auth.Pages.Denuncies)"></app-tool-button>
    <app-tool-button text="Llista" i18n-text (click)="onMakeGridNonEditable(true);" color="has-color-yellow"
        icon="fa-th-list" [disabled]="!this.isReadOnly || !this.auth.UserCanWrite(this.auth.Pages.Denuncies)">
    </app-tool-button>
    <app-tool-button text="Cobrar" i18n-text (click)="cobrarDenuncia();" color="has-color-darkgreen"
        icon="fa-university" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Denuncies)"></app-tool-button>
    <app-tool-button text="Operación" i18n-text (click)="novaOperacio();" color="has-color-purple" icon="fa-plus-circle"
        [disabled]="this.denunciaDetail.tancat || !this.isReadOnly"
        *ngIf="this.auth.UserCanWrite(this.auth.Pages.Denuncies)"></app-tool-button>
    <app-tool-button text="Report" i18n-text (click)="this.report();" color="has-color-blue" icon="fa-print">
    </app-tool-button>
    <app-tool-button text="DGT" i18n-text color="has-color-darkblue" src="assets/images/dgtBig.png" (click)="OpenDgt()"
        *ngIf="this.denunciaDetail.tipusIdInfraccio !== 'S'"></app-tool-button>
    <app-tool-button text="Notificacions" i18n-text color="has-color-aqua" icon="fa-bell" (click)="OpenNotifications()"
        [disabled]="!this.auth.UserCanSeePage(this.auth.Pages.NotificacionsExpedient)"></app-tool-button>
    <app-tool-button text="Imatges" i18n-text color="has-color-darkblue" icon="fa-images" (click)="onImagesClick()"
        [badge]="this.imatgesCount" [badgeActive]="this.imatgesCount > 0" [disabled]="!this.isReadOnly">
    </app-tool-button>
    <app-tool-button text="Sol. retirada grua" i18n-text (click)="solicitarRetiradaGrua();" color="has-color-orange"
        icon="fa-truck-pickup"
        [disabled]="!this.hasGruaAgent || this.denunciaDetail.tieneRetiradaGrua || !this.isReadOnly"
        *ngIf="this.auth.UserCanWrite(this.auth.Pages.GenerarProposta)"></app-tool-button>
    <!-- <app-tool-button text="Eliminar" i18n-text (click)="removeDenuncia();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.isReadOnly || !this.auth.UserCanDelete(this.auth.Pages.Denuncies)"></app-tool-button> -->
</app-toolbar>
<app-dialog-body style="max-height: 100% !important">
    <form #mainForm>
        <div ng-init="isDisabled = true">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-xl-4">
                            <mat-form-field>
                                <input id="denuncies-editor-exp" class="important" matInput i18n-placeholder
                                    placeholder="Expedient" i18n-placeholder [value]="this.denunciaDetail.expedient"
                                    (input)="this.denunciaDetail.expedient=$event.target.value" [readonly]="true">
                                <app-view-list-icon (viewClick)="getExpedients()" hideList="true"></app-view-list-icon>
                            </mat-form-field>
                        </div>
                        <app-form-text-edit id="denuncies-editor-expExt" class="col-sm-4" i18n-label label="Exp. extern"
                            [(value)]="this.denunciaDetail.expedientExtern"></app-form-text-edit>
                        <div class="col-sm-2">
                            <button class="btn btn-outline-primary" (click)="openMap()" [disabled]="this.mapDisabled">
                                <i class="fas fa-map-marked-alt"></i>
                                <span style="margin-left:1vh">Mapa</span>
                            </button>
                        </div>
                      
                        <app-form-text-edit class="col-sm-2" *ngIf="!(this.denunciaDetail.objGruaEstat === undefined ||
                        this.denunciaDetail.objGruaEstat === null ||
                        this.denunciaDetail.objGruaEstat === '')"  class="col-sm-2" i18n-label label="Retirada"  [disabled]="true"
                        [value]="this.denunciaDetail.objGruaEstat"></app-form-text-edit>
                      
                    </div>
                    <div class="row">
                        <app-form-date-edit id="denuncies-editor-data" class="col-xl-4" i18n-label label="Data"
                            [(value)]="this.denunciaDetail.data" type="calendar"
                            (valueChange)="this.calcularDataDescompte()" [isReadOnly]="this.isReadOnly" required>
                        </app-form-date-edit>
                        <app-form-text-edit id="denuncies-editor-hora" class="col-sm-4" type="time" i18n-label
                            label="Hora" [(value)]="this.denunciaDetail.hora" [isReadOnly]="this.isReadOnly" required>
                        </app-form-text-edit>
                    </div>

                    <div class="row">
                        <div class="col-sm-9">
                            <!-- <app-form-text-edit i18n-label label="Carrer" *ngIf="this.isReadOnly" [(value)]="this.denunciaDetail.carrer" [isReadOnly]="this.isReadOnly" required></app-form-text-edit> -->
                            <app-combo-box-input-autocomplete [disableComponent]="this.isReadOnly" #comboCalle
                                class="center-horizontal" i18n-label label="Carrer" [options]="this.callesDictionary"
                                (valorSeleccionado)="this.calleSeleccionado($event)"
                                [valorAutocomplete]="this.valorAutoCompleteCalleDictionary">
                            </app-combo-box-input-autocomplete>
                            <app-view-list-icon (listClick)="seleccionarCarrer()"
                                (viewClick)="seleccionarCarrer(this.denunciaDetail.secure_id_carrer)"
                                [hideList]="this.isReadOnly"></app-view-list-icon>
                        </div>
                        <app-form-text-edit id="denuncies-editor-numero" class="col-xl-3" i18n-label label="Número"
                            [(value)]="this.denunciaDetail.numero" maxLenght="4" [isReadOnly]="this.isReadOnly"
                            required></app-form-text-edit>
                    </div>

                    <div class="row" *ngIf="this.denunciaDetail.tipusIdInfraccio !== 'S'">
                        <div class="col-xl-3">
                            <div style="display: flex; align-items: center; padding-right: 10px;">
                                <app-form-text-edit style="flex: 3;" id="denuncies-editor-matricula" i18n-label label="Matrícula"
                                    [(value)]="this.denunciaDetail.matricula" [isReadOnly]="true"></app-form-text-edit>
                                
                                <img *ngIf="this.denunciaDetail.etiqueta !== null && this.denunciaDetail.etiqueta !== ''"
                                    [src]="getImatgeEtiqueta(this.denunciaDetail.etiqueta)"
                                    style="height: 30px; vertical-align: middle;">
                            </div>
                            
                            <app-view-list-icon (listClick)="seleccionarVehicle()"
                                (viewClick)="seleccionarVehicle(this.denunciaDetail.matricula)" [hideList]="true">
                            </app-view-list-icon>
                        </div>

                        <app-form-text-edit id="denuncies-editor-pais" class="col-xl-3" i18n-label label="Pais"
                            [(value)]="this.denunciaDetail.paisDesc" [disabled]="true"></app-form-text-edit>
                        <app-form-text-edit id="denuncies-editor-marca" class="col-xl-3" i18n-label label="Marca"
                            [(value)]="this.denunciaDetail.marca" [disabled]="true"></app-form-text-edit>
                        <app-form-text-edit id="denuncies-editor-model" class="col-xl-3" i18n-label label="Model"
                            [(value)]="this.denunciaDetail.modelo" [disabled]="true"></app-form-text-edit>


                    </div>

                    <mat-tab-group class="has-frame">
                        <mat-tab>
                            <ng-template mat-tab-label i18n-label>{{this.denunciaDetail.tipusIdInfraccio !== 'S' ?
                                'Conductor': 'Infractor'}}</ng-template>
                            <div class="row">
                                <app-form-text-edit id="denuncies-editor-dni" class="col-xl-9" i18n-label
                                    label="DNI/PASP" [(value)]="this.denunciaDetail.dni_conductor"
                                    [isReadOnly]="this.isReadOnly" (change)="this.consultarConductor()">
                                </app-form-text-edit>
                                <app-form-check-edit label="Es menor" i18n-label [(value)]="this.denunciaDetail.esMenor"
                                    [disabled]="this.isReadOnly"></app-form-check-edit>
                                <app-view-list-icon (listClick)="this.seleccionarConductor()"
                                    (viewClick)="this.seleccionarConductor(this.denunciaDetail.secure_id_conductor)"
                                    [hideList]="this.isReadOnly"></app-view-list-icon>
                            </div>

                            <div class="row">
                                <app-form-text-edit id="denuncies-editor-nom" class="col-xl-9" i18n-label label="Nom"
                                    [(value)]="this.denunciaDetail.nom_conductor" [isReadOnly]="this.isReadOnly">
                                </app-form-text-edit>
                                <div class="col" *ngIf="this.denunciaDetail.reincidencias!==undefined && this.denunciaDetail.reincidencias>0">
                                    <button class="btn btn-warning" (click)="openReincidencias()">
                                        <!-- <i class="fas fa-map-marked-alt"></i> -->
                                        <span style="margin-left:1vh">{{this.denunciaDetail.reincidencias+1}} Reincidencias</span>
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <app-combo-box-input #comboDomConductor style="width:98%; padding-left: 2%;" i18n-label
                                    label="Domicili" [showingOptions]="this.domicilisConductorDictionary.value"
                                    [disabled]="this.isReadOnly || this.domicilisConductorDictionary.value.length===0 || this.domicilisConductorDictionary.value.length===1"
                                    (optionChanged)="this.domicilioConductorSeleccionado($event)">
                                </app-combo-box-input>
                            </div>
                        </mat-tab>

                        <mat-tab i18n-label label="Propietari" *ngIf="this.denunciaDetail.tipusIdInfraccio !== 'S'">
                            <div class="row">
                                <app-form-text-edit id="denuncies-editor-dniProp" class="col-xl-9" i18n-label
                                    label="DNI/PASP" [(value)]="this.denunciaDetail.dni_propietari"
                                    [isReadOnly]="this.isReadOnly" (change)="this.consultarPropietari()">
                                </app-form-text-edit>
                                <app-view-list-icon class="col-xl-2" (listClick)="this.seleccionarPropietari()"
                                    (viewClick)="this.seleccionarPropietari(this.denunciaDetail.secure_id_propietari)"
                                    [hideList]="this.isReadOnly"></app-view-list-icon>
                                <mat-checkbox class="col-xl-1" [disabled]="this.isReadOnly"
                                    [checked]="this.denunciaDetail.juridica_propietari"
                                    (input)="this.denunciaDetail.juridica_propietari=$event.target.value">PJ
                                </mat-checkbox>
                            </div>

                            <div class="row">
                                <app-form-text-edit id="denuncies-editor-nomProp" class="col-xl-12" i18n-label
                                    label="Nom" [(value)]="this.denunciaDetail.nom_propietari"
                                    [isReadOnly]="this.isReadOnly"></app-form-text-edit>
                            </div>

                            <div class="row">
                                <app-combo-box-input #comboDomPropietari style="width: 98%; padding-left: 2%;"
                                    label="Domicili" [showingOptions]="this.domicilisPropietariDictionary.value"
                                    [disabled]="this.isReadOnly || domicilisPropietariDictionary.value.length===0 || domicilisPropietariDictionary.value.length===1"
                                    (optionChanged)="this.domicilioPropietarioSeleccionado($event)">
                                </app-combo-box-input>
                            </div>
                        </mat-tab>
                        <mat-tab i18n-label label="Tutor legal" *ngIf="this.denunciaDetail.esMenor">
                            <div class="row">
                                <app-form-text-edit id="denuncies-editor-dniTutorLegal" class="col-xl-9" i18n-label
                                    label="DNI/PASP" [required]="this.denunciaDetail.esMenor"
                                    [(value)]="this.denunciaDetail.dni_tutorLegal" [isReadOnly]="this.isReadOnly"
                                    (change)="this.consultarPropietari()"></app-form-text-edit>
                                <app-view-list-icon (listClick)="this.seleccionarTutorLegal()"
                                    (viewClick)="this.seleccionarTutorLegal(this.denunciaDetail.secure_id_tutorLegal)"
                                    [hideList]="this.isReadOnly"></app-view-list-icon>
                            </div>
                            <div class="row">
                                <app-form-text-edit disabled id="denuncies-editor-nomTutorLegal" class="col-xl-12"
                                    i18n-label label="Nom" [(value)]="this.denunciaDetail.nom_tutorLegal"
                                    [isReadOnly]="this.isReadOnly"></app-form-text-edit>
                            </div>

                            <div class="row">
                                <app-combo-box-input #comboDomTutorLegal style="width: 98%; padding-left: 2%;"
                                    label="Domicili" [showingOptions]="this.domicilisTutorLegalDictionary.value"
                                    [disabled]="this.isReadOnly || this.domicilisTutorLegalDictionary.value.length===0 || this.domicilisTutorLegalDictionary.value.length===1"
                                    (optionChanged)="this.domicilioTutorLegalSeleccionado($event)">
                                </app-combo-box-input>
                            </div>
                        </mat-tab>
                        <mat-tab i18n-label label="Establecimiento" *ngIf="this.denunciaDetail.EstablecimientoCIF">
                            <div class="row">
                                <app-form-text-edit id="denuncies-editor-cifEstablecimiento" class="col-xl-9" i18n-label
                                    label="DNI/PASP" [(value)]="this.denunciaDetail.EstablecimientoCIF"
                                    [isReadOnly]="true">
                                </app-form-text-edit>
                            </div>
                            <div class="row">
                                <app-form-text-edit disabled id="denuncies-editor-nomEstablecimiento" class="col-xl-12"
                                    i18n-label label="Nom" [(value)]="this.denunciaDetail.EstablecimientoRazonSocial"
                                    [isReadOnly]="true"></app-form-text-edit>
                            </div>                            
                        </mat-tab>
                        <mat-tab i18n-label label="Trabajador" *ngIf="this.denunciaDetail.EstablecimientoTrabajadorNIE">
                            <div class="row">
                                <app-form-text-edit id="denuncies-editor-nifTrabajador" class="col-xl-9" i18n-label
                                    label="DNI/PASP" [(value)]="this.denunciaDetail.EstablecimientoTrabajadorNIE"
                                    [isReadOnly]="true">
                                </app-form-text-edit>
                            </div>
                            <div class="row">
                                <app-form-text-edit disabled id="denuncies-editor-nomTrabajador" class="col-xl-12"
                                    i18n-label label="Nom" [(value)]="this.denunciaDetail.EstablecimientoTrabajadorNombre"
                                    [isReadOnly]="true"></app-form-text-edit>
                            </div>                            
                        </mat-tab>
                    </mat-tab-group>
                    <div class="row" style="margin-left:5px" *ngIf="this.denunciaDetail.tipusIdInfraccio !== 'S'">

                        <mat-checkbox [disabled]="true" [checked]="this.denunciaDetail.judicial" i18n>Judicial &nbsp;
                        </mat-checkbox>

                        <app-form-check-edit style="margin-right: 5px;" [disabled]="this.isReadOnly" i18n-label
                            label="DGT" [(value)]="this.denunciaDetail.jpt"></app-form-check-edit>

                        <app-form-check-edit [disabled]="this.isReadOnly" i18n-label label="SCT"
                            [(value)]="this.denunciaDetail.sct"></app-form-check-edit>

                        <div class="col">
                            <mat-checkbox [disabled]="true" style="float:right" [checked]="this.denunciaDetail.tancat"
                                i18n>CERRADO </mat-checkbox>
                        </div>
                    </div>

                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <mat-form-field>
                                <input id="denuncies-editor-numDen" class="important" matInput i18n-placeholder
                                    placeholder="Número denúncia" [value]="this.denunciaDetail.numeroDenuncia"
                                    (input)="this.denunciaDetail.numeroDenuncia=$event.target.value" [readonly]="true">
                            </mat-form-field>
                        </div>

                        <div class="col-sm-3">
                            <app-form-text-edit id="denuncies-editor-agent" i18n-label label="Agent"
                                [(value)]="this.denunciaDetail.codiAgent" [isReadOnly]="this.isReadOnly" required>
                            </app-form-text-edit>
                            <app-view-list-icon (listClick)="this.seleccionarAgent()"
                                (viewClick)="this.seleccionarAgent(this.denunciaDetail.secure_id_agent)"
                                [hideList]="this.isReadOnly"></app-view-list-icon>
                        </div>

                        <div class="col-sm-3">
                            <app-form-text-edit id="denuncies-editor-agent" i18n-label label="Segon agent"
                                [(value)]="this.denunciaDetail.codiAgent2" [isReadOnly]="this.isReadOnly">
                            </app-form-text-edit>
                            <app-view-list-icon (listClick)="this.seleccionarAgent2()"
                                (viewClick)="this.seleccionarAgent2(this.denunciaDetail.secure_id_agent2)"
                                [hideList]="this.isReadOnly"></app-view-list-icon>
                        </div>
                    </div>

                    <div class="form-group">
                        <mat-form-field>
                            <input id="denuncies-editor-codAnul" matInput i18n-placeholder placeholder="Cod. Anul·lació"
                                [value]="this.denunciaDetail.codiAnulacio"
                                (input)="this.denunciaDetail.codiAnulacio=$event.target.value" [readonly]="true">
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <app-combo-box-input #comboZonesCarrer [label]="'Zona'"
                            [showingOptions]="this.zonesComboDictionary.value"
                            (optionChanged)="this.zonaCarrerSeleccionado($event)"
                            [(value)]="this.denunciaDetail.secure_id_zona" [disabled]="this.isReadOnly">
                        </app-combo-box-input>
                    </div>

                    <mat-tab-group class="has-frame">
                        <mat-tab>
                            <ng-template mat-tab-label i18n-label label="Infracció">
                                <span i18n>Infracció</span>
                                <app-view-list-icon (listClick)="this.seleccionarLegislacio()"
                                    (viewClick)="this.seleccionarLegislacio(this.denunciaDetail.secure_id_infraccio)"
                                    [hideList]="this.isReadOnly"></app-view-list-icon>
                            </ng-template>
                            <div class="row">
                                <!-- Llei -->
                                <div class="col-sm-5">
                                    <app-form-text-edit id="denuncies-editor-llei" i18n-label label="Llei"
                                        [(value)]="this.denunciaDetail.lleiDesc" [isReadOnly]="true" required>
                                    </app-form-text-edit>
                                </div>
                                <!-- Article -->
                                <div class="col-sm-2">
                                    <mat-form-field>
                                        <input id="denuncies-editor-article" matInput i18n-placeholder
                                            placeholder="Article" [value]="this.denunciaDetail.article"
                                            (input)="this.denunciaDetail.article=$event.target.value" [readonly]="true">
                                    </mat-form-field>
                                </div>
                                <!-- Tipus denuncia -->
                                <app-combo-box-input #comboTipusDenuncia class="col-sm-5" i18n-label
                                    label="Tipus de denúncia" [showingOptions]="this.tipusDenunciesDictionary.value"
                                    disabled (optionChanged)="this.tipusDenunciaSeleccionado($event)"
                                    [(value)]="this.denunciaDetail.tipusDenuncia" [disabled]="true" [required]="true">
                                </app-combo-box-input>
                            </div>

                            <!-- Infraccio descripcio -->
                            <div class="form-group">
                                <div *ngIf="this.denunciaDetail.descripcioInfraccio !== null">
                                    <app-form-text-area [(value)]="this.denunciaDetail.descripcioInfraccio"
                                        [disabled]="true"></app-form-text-area>
                                </div>
                                <div *ngIf="this.denunciaDetail.descripcioInfraccio === null">
                                    <app-combo-box-input-autocomplete #comboInfraccion
                                        [disableComponent]="this.isReadOnly" class="center-horizontal" i18n-label
                                        label="Infracció" [options]="this.infraccionesDictionary"
                                        (valorSeleccionado)="this.infraccioSeleccionado($event)"
                                        [valorAutocomplete]="this.valorAutoCompleteInfraccionesDictionary">
                                    </app-combo-box-input-autocomplete>
                                </div>
                            </div>

                            <div class="row">
                                <!-- Import -->
                                <app-form-currency-text-edit id="denuncies-editor-import" class="col-xl-4" i18n-label
                                    label="Import" [(value)]="this.denunciaDetail.import"
                                    (valueChange)="this.calcularImportDescompte()"
                                    [isReadOnly]="this.isReadOnly ||(!this.isReadOnly && this.denunciaDetail.tipusIdInfraccio !== 'S')">
                                </app-form-currency-text-edit>
                                <!-- Qualificació -->
                                <app-combo-box-input #comboTipusGravetat class="col-xl-4" i18n-label
                                    label="Qualificació" [showingOptions]="this.tipusGravetatDictionary.value"
                                    (optionChanged)="this.tipusGravetatSeleccionado($event)"
                                    [(value)]="this.denunciaDetail.qualificacio" [disabled]="true" [required]="true">
                                </app-combo-box-input>
                                <!-- Punts -->
                                <app-form-text-edit id="denuncies-editor-punts" class="col-xl-4" i18n-label
                                    label="Punts" *ngIf="this.denunciaDetail.tipusIdInfraccio !== 'S'"
                                    [(value)]="this.denunciaDetail.punts" [isReadOnly]="true"></app-form-text-edit>
                            </div>

                            <div class="row" *ngIf="this.denunciaDetail.IMPORTE_SEGUNDA_ANULACION === undefined || this.denunciaDetail.IMPORTE_SEGUNDA_ANULACION === null ">
                                <app-form-currency-text-edit id="denuncies-editor-impDesc"
                                    [ngClass]="[this.denunciaDetail.tipusDenuncia==='Z'?'col-xl-4':'col-xl-6']"
                                    i18n-label label="Imp. descompte" [(value)]="this.denunciaDetail.importDescompte"
                                    [isReadOnly]="true"></app-form-currency-text-edit>
                                <app-form-date-edit id="denuncies-editor-dataDesc"
                                    [ngClass]="[this.denunciaDetail.tipusDenuncia==='Z'?'col-xl-4':'col-xl-6']"
                                    i18n-label label="Data" [(value)]="this.denunciaDetail.dataDescompte"
                                    type="calendar" [isReadOnly]="true"></app-form-date-edit>
                                <app-form-currency-text-edit id="denuncies-editor-impAnul"
                                    *ngIf="this.denunciaDetail.tipusDenuncia==='Z'" class="col-xl-4" i18n-label
                                    label="Imp. Anul·lació" [(value)]="this.denunciaDetail.importAnulacio "
                                    [isReadOnly]="true"></app-form-currency-text-edit>

                                <app-form-currency-text-edit id="denuncies-editor-impDesc"
                                    class="col-xl-4" i18n-label label="Imp. 2ª anul" [(value)]="this.denunciaDetail.IMPORTE_SEGUNDA_ANULACION"
                                    [isReadOnly]="true"></app-form-currency-text-edit>

                                <app-form-text-edit id="denuncies-editor-punts" *ngIf="this.denunciaDetail.zbAnulacioParkeon2"
                                    class="col-xl-4" i18n-label label="Data Inici 2ª anul" 
                                    [(value)]="this.denunciaDetail.DATA_INICI_SEGONA_ANULACIO_string" [isReadOnly]="true"></app-form-text-edit>
                                
                                <app-form-text-edit id="denuncies-editor-punts" *ngIf="this.denunciaDetail.zbAnulacioParkeon2"
                                    class="col-xl-4" i18n-label label="Data Fi 2ª anul" 
                                    [(value)]="this.denunciaDetail.DATA_FI_SEGONA_ANULACIO_string" [isReadOnly]="true"></app-form-text-edit>
                            </div>
                            <!-- <div class="row" *ngIf="this.denunciaDetail.IMPORTE_SEGUNDA_ANULACION !== undefined && this.denunciaDetail.IMPORTE_SEGUNDA_ANULACION !== null ">
                                <app-form-currency-text-edit id="denuncies-editor-impDesc"
                                    [ngClass]="[this.denunciaDetail.tipusDenuncia==='Z'?'col-xl-2':'col-xl-2']"
                                    i18n-label label="Imp. descompte" [(value)]="this.denunciaDetail.importDescompte"
                                    [isReadOnly]="true"></app-form-currency-text-edit>
                                <app-form-date-edit id="denuncies-editor-dataDesc"
                                    [ngClass]="[this.denunciaDetail.tipusDenuncia==='Z'?'col-xl-2':'col-xl-2']"
                                    i18n-label label="Data" [(value)]="this.denunciaDetail.dataDescompte"
                                    type="calendar" [isReadOnly]="true"></app-form-date-edit>
                                <app-form-currency-text-edit id="denuncies-editor-impAnul"
                                    *ngIf="this.denunciaDetail.tipusDenuncia==='Z'" class="col-xl-2" i18n-label
                                    label="Imp. Anul·lació" [(value)]="this.denunciaDetail.importAnulacio "
                                    [isReadOnly]="true"></app-form-currency-text-edit>
                            </div> -->
                        </mat-tab>

                        <mat-tab>
                            <ng-template mat-tab-label>
                                <span *ngIf="this.denunciaDetail.observacions===null" i18n>Observacions</span>
                                <span *ngIf="this.denunciaDetail.observacions!==null" style="color:red"
                                    i18n>Observacions (1)</span>
                            </ng-template>
                            <div class="form-group">
                                <mat-form-field>
                                    <textarea cols="20" matInput [value]="this.denunciaDetail.observacions"
                                        (input)="this.denunciaDetail.observacions=$event.target.value"
                                        [readonly]="this.isReadOnly"></textarea>
                                </mat-form-field>
                            </div>
                        </mat-tab>

                        <mat-tab>
                            <ng-template mat-tab-label>
                                <span *ngIf="this.denunciaDetail.alegacio===null" i18n>Al·legacions</span>
                                <span *ngIf="this.denunciaDetail.alegacio!==null" style="color:red" i18n>Al·legacions
                                    (1)</span>
                            </ng-template>
                            <div class="form-group">
                                <mat-form-field>
                                    <textarea cols="20" matInput [value]="this.denunciaDetail.alegacio"
                                        (input)="this.denunciaDetail.alegacio=$event.target.value"
                                        [readonly]="this.isReadOnly"></textarea>
                                </mat-form-field>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>


        <mat-tab-group class="has-frame">
            <mat-tab i18n-label label="Estat Operació">
                <app-estat-operacio [data]=this.denunciaDetail [isReadOnly]="true"></app-estat-operacio>
            </mat-tab>  
            <mat-tab i18n-label label="Dades Expedient">
                <app-dades-expedient [data]=denunciaDetail [isReadOnly]="true"></app-dades-expedient>
            </mat-tab>
            <mat-tab *ngIf="this.denunciaDetail.tipusIdInfraccio !== 'S'">
                <ng-template mat-tab-label>
                    <span *ngIf="this.denunciaDetail.numRetirada===null" i18n>Retirada</span>
                    <span *ngIf="this.denunciaDetail.numRetirada!==null" style="color:red" i18n>Retirada (1)</span>
                </ng-template>
                <app-retirada [data]=denunciaDetail [isReadOnly]=this.isReadOnly></app-retirada>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span *ngIf="this.denunciaDetail.Operaciones.length===0" i18n>Operaciones</span>
                    <span *ngIf="this.denunciaDetail.Operaciones.length!==0" style="color:red" i18n>Operaciones
                        ({{this.denunciaDetail.Operaciones.length}})</span>
                </ng-template>
                <app-operacions [data]=denunciaDetail.Operaciones [isReadOnly]=this.isReadOnly
                    [idDenuncia]=this.denunciaDetail.secure_id_denuncia [somIdDenuncia]="this.denunciaDetail.somId" (updated)="this.refrescar()"></app-operacions>
            </mat-tab>
            <mat-tab i18n-label label="Referències C60">
                <app-ref-c60 [data]=denunciaDetail.SOM_C60></app-ref-c60>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span *ngIf="this.denunciaDetail.SOM_EXPREL.length===0" i18n>Exp. Relacionats</span>
                    <span *ngIf="this.denunciaDetail.SOM_EXPREL.length!==0" style="color:red" i18n>Exp. Relacionats
                        ({{this.denunciaDetail.SOM_EXPREL.length}})</span>
                </ng-template>
                <app-exp-relacionats [data]=denunciaDetail.SOM_EXPREL></app-exp-relacionats>
            </mat-tab>
            <mat-tab i18n-label label="Denunciant">
                <app-denunciant [data]=denunciaDetail [isReadOnly]=this.isReadOnly></app-denunciant>
            </mat-tab>
            <mat-tab i18n-label label="Recaptació">
                <app-recaptacio [data]=denunciaDetail [isReadOnly]=this.isReadOnly></app-recaptacio>
            </mat-tab>
        </mat-tab-group>
    </form>
</app-dialog-body>