import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { TiquetsClientFilter } from '../tiquets-client/tiquets-client-filter/tiquets-client-filter';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable({
  providedIn: 'root'
})
export class TiquetsClientFilterService extends CustomFilterService {

  public createFilterParams(filter: TiquetsClientFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    let fechaTicketInicio = '';
    let fechaTicketFin = '';

    if (filter.fechaTicketInicio !== null) {
      fechaTicketInicio = this.formatDateToDecimal(this.stringToDate(filter.fechaTicketInicio));
    }
    if (filter.fechaTicketFin !== null) {
      fechaTicketFin = this.formatDateToDecimal(this.stringToDate(filter.fechaTicketFin), false);
    }

    let httpParams =  new HttpParams()
      .append('id', filter.id ? filter.id.toString() : '')
      .append('matricula', filter.matricula)
      .append('email', filter.email)
      .append('zona', filter.zona ? filter.zona.toString() : '')
      .append('proveidors', filter.proveidors)
      .append('start', start.toString())
      .append('size', pageSize.toString())
      .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
      .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
      httpParams = httpParams
      .append('fechaTicketInicio', fechaTicketInicio)
      .append('fechaTicketFin', fechaTicketFin);
    return httpParams;
  }

  formatDateToDecimal(date: Date, isFechaIni: boolean = true): string {
    let month: string;
    let day: string;
    let hhmmss: string;

    month = (date.getMonth() + 1).toString();
    if (month.length === 1) {
      month = '0' + month;
    }

    day = date.getDate().toString();
    if (day.length === 1) {
      day = '0' + day;
    }

    if (isFechaIni) {
      hhmmss = '000000';
    } else {
      hhmmss = '235959';
    }

    return date.getFullYear().toString()
            + month
            + day
            + hhmmss;
  }

  stringToDate (value) {
    if (!this.isDate (value)) {
      value = new Date(value);
    }
    return value;
  }

  isDate (value) {
    return value instanceof Date;
  }


}
